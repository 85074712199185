<template>
  <div>Forbidden</div>
</template>

<script>
export default {
  //
};
</script>

<style lang="scss" scoped></style>
